.navbar {
  background:white;
  height: 80px;
  display: flex;
  justify-content: right;
  align-items: right;
  font-size: 1.2rem;
  position:fixed;
  top: 0;
  
}


.navbar-container {
  display: flex;
  background:rgb(255,255,255,1);
  justify-content: right;
  align-items: right;
  height: 80px;
  max-width: 100%;

  padding-right: 10px;
  position:fixed;
  top: 0;
  z-index: 1030;
}

.navbar-logo {
  color:#0D9F98;
  justify-self: start;
  margin-left: 20px;
  margin-top: 20px;
  max-width: 90px;
  max-height: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.nav-menu {
  display: inline-flex; 
  list-style: none;
  text-align: right;
  width: 100vw;
  justify-content: flex-end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
  
}

.nav-links {
  color: #000;
  display: flex;
  align-items: right;
  font-family: Thasadith;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  padding: 0.5rem 1rem;
  height: 80%;

}

.fa-bars {
  color: #000;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.nav-links:focus, .nav-links:active {
  color:  #0D9F98 !important;
 
  
}
 .nav-links:hover {
  color:  #0D9F98 !important;
  border-bottom: 4px solid #0D9F98;
  transition: all 0.2s ease-out;
 
}

.frmbtn2 { 
  color: #000;
  width: 120px;
  height: 40px;
  background: #06aea6 0% 0% no-repeat padding-box !important;
  border-radius: 2px !important;
  border-color: black;
  opacity: 1;
  text-align: center;
  border: inherit;
  font-size: 18px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.frmbtn2:hover {
  color: grey;
}
@media screen and (max-width: 960px) {
  .navbar-container {
    position:unset;
    top:unset;
  }
  .NavbarItems {
    position: relative;
  }


  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    justify-content: center;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #83f5f0;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 80%;
    display: table;
  }

  .nav-links:hover {
    background-color: #83f5f0;
    border-bottom: 2px solid #0D9F98;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    width:90px;
    height:30px;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .frmbtn2 {
    margin-top: 20px;
    margin-left: 90px;
  }

  .fa-times {
    color: #000;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 100%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}