.side-drawer {
    height: 300px;
    background-color: white;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    max-width: 100%;
    z-index: 1030;
    transform: translateY(-100%);
    transition: transform 0.3s ease-out;
  }
  .side-drawer  .closebtn {
    position: absolute;
    top: 245px;
    right: 20px;
    height:50px;
    font-size: 36px;
    margin-left: 50px;
    border:none;
    background-color: white;
    transition: all 0.5s ease;
  }
  .side-drawer.open {
    transform: translateX(0);
  }