@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

@font-face {
  font-family: 'Thasadith';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Thasadith\ Regular\ 400.ttf');
  /* IE9 Compat Modes */

}

@font-face {
  font-family: 'Muli';
  src: url('./fonts/Muli-Regular.ttf')
}

.App {
  text-align: center;
  top:200px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}
.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.PhoneInputInput {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.PhoneInputInput:focus {
  outline: unset !important;
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.bg-dark {
  background-color: #FFFFFF !important;
}



.bacground_lightgray {
  background-color: #E0E0E0;
  color: #282828;
  padding-bottom: 30px;
  padding-top: 30px;
}

.bacground_lightbluegray {
  background-color: #CFD8DC;
  padding-bottom: 30px;
  padding-top: 30px;
}

h2.gradient-text {
  color: #34495e;
}

p.sub_text {
  font-size: 16px;
  opacity: .65;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.mt_20 {
  margin-top: 20px;
}

.mt_2 {
  margin-top: 2px;
}

.mt_10 {
  margin-top: 10px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_2 {
  margin-bottom: 2px;
}

.tag_middile {
  padding: 50px;
  margin: 50px;
}

.footer {
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  /*max-height: 30px;*/
  font-family: 'Muli', sans-serif;
  /*position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;*/
}

.copyright {
  min-height: 40px;
  background-color: #000000;
}

.copyright p {
  text-align: center;
  color: #FFF;
  padding: 10px 0;
  margin-bottom: 0px;
}

#team .card {
  border: none;
  background: #E0E0E0;
}

.card {
  background-color: #E0E0E0;
  border: 0 !important;
}

/* .image-flip:hover .backside,
.image-flip.hover .backside {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
} */

.mainflip {
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 1s;
  -moz-transition: 1s;
  /* -moz-transform: perspective(1000px); */
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

/* .frontside {
  position: relative;
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  z-index: 2;
} */
.collapse.show {
  background: #B0EADE;
  text-align: center;
  height: 100vh;
}

.bg_img_companylogo {
  background-image: url('./image/logo_new.png');
  background-repeat: no-repeat;

}

.bg_img_front_1 {
  background-image: url('./image/PrimaryPaletteColor.png');

}

.bg_img_front_2 {
  background-image: url('./image/Accent1.png');

}

.bg_img_back {
  background-image: url('./image/Accent2.png');
}

.bg_img_sback_1 {
  background-image: url('./image/MV.jpg');
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  margin-left: 1.5rem;

}

/* .backside {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -o-transition: 1s;
  -o-transform-style: preserve-3d;
  -ms-transition: 1s;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
} */
.back-text {
  color: #FFFFFF;
  text-align: center;
}

.frontside .card,
.backside .card {
  min-height: 250px;
  min-width: 250px;
  border-radius: 0;
}

.backside .card a {
  font-size: 18px;
  color: #058CD5 !important;
  text-decoration: underline !important;
}

.backside .card a:hover {
  color: blueviolet !important;
}

.frontside .card .card-title,
.backside .card .card-title {
  color: #007b5e !important;
}

.frontside .card .card-body img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.card-body {
  font-family: 'Muli', sans-serif;
  ;
  font-style: normal;
  font-weight: normal;
  display: flex;

  align-items: center;
  color: #000000;
}

.card-body .second-card {
  color: #FFFFFF !important;
}

.card-body .serv-second-card {
  color: #000 !important;
}

.frontside {
  font-size: 24px;
  line-height: 31px;

}

.backside {
  font-size: 18px;
  line-height: 23px;
}

.heading7 {
  font-size: 16px;
  font-weight: 700;
  color: #d9d6d6;
  margin-bottom: 22px;
}

.footer-ul {
  list-style-type: none;
  /* padding-left: 0px;
  margin-left: 2px; */
}

.footer-ul li a {
  color: #a0a3a4;
  transition: color 0.2s linear 0s, background 0.2s linear 0s;
}

.empty-page {
  height: 63vh;
  font-size: 40px;
}

.pullDown {
  animation-name: pullDown;
  -webkit-animation-name: pullDown;
  animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  -webkit-transform-origin: 50% 0;
}

.item {
  left: 0;
  top: 0;
  position: relative;
  overflow: hidden;

}

.item img {
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;

}

.item img:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);

}

.img-thumbnail {
  border: 0px;
  border-radius: 0px;
}

.bg-dark {
  background-color: black !important;
}


.keyimage {
  width: 80%;
  height: 100%;
}

.image {
  width: 70%;
  height: 100%;
  float: right;
}



@media (max-width: 480px) {
  .image {
    width: 80%;
    float: unset;
  }

  .p-all-100 {
    align-items: center;
    text-align: start;
    padding-top: 40px !important;
    float: unset;
  }

  .p-all {
    padding: 20px;
  }

  .player-wrapper div[style] {
    height: 300px !important;
    width: 300px !important;
  }

  .player-wrapper {
    margin-left: unset;
  }


  .lbl_txt {
    font-size: 15px !important;
  }

  .title-txt {
    font-size: 18px !important;

  }

  .comp-text {
    height: unset !important;
  }

  .modal-video-body {
    width: 80% !important;
  }

  .modal-video-close-btn {
    width: 25px !important;
    height: 25px !important;
  }

  .react-responsive-modal-modal {
    max-width: "unset";
    padding: "unset";
    margin-top: "80px"
  }

  .player div[style] {
    height: unset !important;
    width: unset !important;
  }
}

.keyimage1 {
  width: 50%;
  height: 100%;
}

.bg_img {
  background: url('./image/PrimaryPaletteColor.png');
}

.navbrand {
  width: 30%;
  float: left;
}

@media (max-width: 480px) {
  .navbrand {
    width: 220px;
    height: 67px;
    float: left;
  }
}



.content {
  font-family: 'Muli', sans-serif;
  ;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  align-items: center;
  text-align: left;
  line-height: 23px;
  color: #000000;
  margin-left: 5px;
}

.footer-text {
  padding-top: 25px;
}

.social {
  float: right;
  margin-top: 45px;
}

.react-rotating-text-cursor {
  animation: unset !important;
}

.comp-text {
  width: 100% !important;
  /* height: 75px; */
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.contact-text {
  width: 100% !important;
  border: 1px solid #0D9F98;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.input-text {
  width: 100% !important;
  border: 1px solid #0D9F98;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 26px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.prod-text {
  width: 100% !important;
  border: none;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.contact {

  width: 100% !important;
  /* height: 75px; */
  border: 1px solid #0D9F98;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
}
.contact-val{

  width: 100% !important;
  /* height: 75px; */
  border: 1px solid #0D9F98;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
}

.center-text {
  width: 100% !important;
  height: 60px;
  background: #B0EADE;
  font-family: 'Muli', sans-serif;
  ;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
}

.fade-text {
  font-family: 'Muli', sans-serif;
  ;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
}

.header-text {
  width: 100% !important;
  background: #fff;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: start;
  padding: 20px;
}

.lbl_txt {
  text-align: left;
  float: left;
  margin-top: 5px;
}

.p-all-100 {
  align-items: center;
  text-align: start;
  padding-top: 100px;
  font-size: 18px;
  line-height: 23px;
}

.p-all {
  padding: 160px;
}

.service-txt {
  align-items: center;
  text-align: start;
  font-size: 18px;
  line-height: 23px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Muli', sans-serif;
  ;
  font-style: normal;
  font-weight: normal;
}

.p-all-60 {
  padding: 80px;
}

.txt {
  font-family: 'Muli', sans-serif;
  ;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 31px;
  align-items: center;
  color: #058CD5;
  text-align: start;
}

.title-txt {
  font-family: 'Muli', sans-serif;
  ;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  color: #000;
  padding-top: 5px;
}


/* .player-wrapper iframe{
  max-width: 600px;
} */
.submitBtn {
  width: 200px;
  height: 41.2px;
  background: #0D9F98 !important;
}

.closebtn {
  top: 0;
  right: 10px;
  font-size: 25px;
  font-weight: lighter;
  color: gray !important;
}


#__react-alert__ div[style] {
  text-transform: unset !important;
}

.sup_li {
  position: absolute;
  left: 3px;
  top: 5px;
}

.red_star {
  color: #f6113a;
  font-size: 11px;
}

.error-lbl {
  font-size: 11px;
  position: absolute;
  /* left: 15px; */
  right: 17px;
  top: 35px;
  color: #f6113a;
}

.error-lbl-textarea {
  font-size: 14px;
  position: absolute;
  /* left: 15px; */
  text-align: center;
  /* top: 0px; */

  color: #f6113a;
}

.react-responsive-modal-modal {
  max-width: "unset";
  width: "70%";
  height: "80%";
  padding: "unset";
}

/*Company card*/
.card {
  position: absolute;
  border-radius: 10px;
  transition: 0.5s;
}

.company .card {
  background: #B0EADE;
  margin-left:500px;
  margin-right:500px;
  min-width: 300px;
  min-height: 400px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, .1);
  justify-content: center;
  align-content:space-between;
  align-items: center;
}

.company .card:hover {
  box-shadow: 0 30px 70px rgba(0, 0, 0, .2);
}

.card .box {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;

  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}

.card .box .img {
  width: 180px;
  height: 180px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}

.card .box .img img {
  width: 100%;
  height: 100%;
}

.card .box h2 {
  font-size: 20px;
  color: #058CD5;
  margin: 20px auto;
}

.card .box h2 span {
  font-size: 14px;
  background-image: url('./image/Accent1.png');
  color: #fff;
  display: inline-block;
  padding: 4px 10px;
  border-radius: 15px;
}

.card .box p {
  color: #262626;
  vertical-align: bottom;
}

.card .box span {
  display: inline-flex;
}

.card .box ul {
  margin: 0;
  padding: 0;
}

.card .box ul li {
  list-style: none;
  float: left;
}

.card .box ul li a {
  display: block;
  color: rgb(36, 34, 34);
  margin: 0 10px;
  font-size: 20px;
  transition: 0.5s;
  text-align: center;
}

.card .box li:hover {
  color: #686a6c;

  cursor: pointer;

}

.card .box ul button li:hover a {
  color: #058CD5;
  transform: rotateY(180deg);
  cursor: pointer;
  transition: 3s ease-out;
}

.gray {
  filter: grayscale(2);
}
.link{
  cursor: pointer !important;
  color: #058CD5;
  text-decoration: underline;
}
.link:hover{
  color: blueviolet;
}

.img img:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.img img {
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
  cursor: pointer;
}
.desc {
  margin-top: 60px;
  min-height: 200px;
  min-width: 200px;
}


.frmSbtn2 {
  width: inherit;
  height: 80px;
  background: #fff 0% 0% no-repeat padding-box !important;
  border: none;
  opacity: 1;
  color: #000;
  text-align: center;
  font-size: 18px;
}
.formErrors{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: bottom;
  color: rgb(212, 112, 112);
}

.td__Prodheader {
  display: flex;
  flex-direction: row;

}

.td__Prodheader-content {
  flex: 1;
  display: flex;
  justify-content: Center;
  align-items: Center;
  flex-direction: row;
  margin-top: 1.5rem;
}

.td__Prodheader-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: grey;
  margin-top: 1.5rem;
}

.td__Prodheader-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 60px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: grey;
}

.td__Prodheader-content h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: -0.04em;
  color: grey;
}

.td__Prodheader-content h3 {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 30px;
  line-height: 26px;
  color: grey;
  margin-top: 1.5rem;
}

.td__Prodheader-content h3:hover {
  color: #0D9F98 !important;
}

.td__Prodheader-content p:hover {
  color: #0D9F98 !important;
}
.td__top{
  padding-top: 2rem; 
}
.td__header {
  display: flex;
  flex-direction: row;  
  
}

.td__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: bottom;
  flex-direction: column;

}

.td__header-content h1 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.04em;
  color: grey;
}

.td__header-content h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: -0.04em;
  color: grey;
}

.td__header-content h3 {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 30px;
  line-height: normal;

  color: grey;
  margin-top: 1.5rem;
}
.td__header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: grey;
  margin-top: 1.5rem;
}

.td__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;

}

.td__header-image img {
  width: 100%;
  height: 100%;
  border: 2px;
  border-radius: 12px;
  border-color: #0D9F98;
}

.td__htp {
  display: flex;
  flex-direction: row;
  
}

.td__htp-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: bottom;
  flex-direction: column;

}

.td__htp-content h1 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.04em;
  color:grey;
}

.td__htp-content h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 60px;
  line-height: 70px;
  letter-spacing: -0.04em;
  color: grey;
}

.td__htp-content h3 {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 30px;
  line-height: normal;

  color: #000;
  margin-top: 1.5rem;
}
.td__htp-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #000;
  margin-top: 1.5rem;
}


@media screen and (max-width: 1050px) {
  .td__header {
    flex-direction: column;
  }

  .td__header-content {
    margin: 0 0 3rem;
  }
  .td__htp {
    flex-direction: column;
  }

  .td__htp-content {
    margin: 0 0 3rem;
  }

  .bg_img_sback_1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    margin-top: 80px;
    margin-bottom: 20px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .contact-val{
  top:10px;
  }
}

@media screen and (max-width: 650px) {
  .td__header-content h2 {
    font-size: 40px;
    line-height: 52px;
  }

  .td__header-content h3 {
    font-size: 14px;
    line-height: 20px;
  }

  .td__header-content h1 {
    font-size: 42px;
    line-height: 56px;
  }

  .td__header-content p {
    font-size: 12px;
    line-height: 18px;
  }

  .td__Prodheader-content h2 {
    font-size: 40px;
    line-height: 52px;
  }

  .td__Prodheader-content h3 {
    font-size: 14px;
    line-height: 22px;
  }

  .td__Prodheader-content h1 {
    font-size: 40px;
    line-height: 56px;
  }

  .td__Prodheader-content p {
    font-size: 12px;
    line-height: 18px;
  }
  .td__htp-content h2 {
    font-size: 40px;
    line-height: 52px;
    font-weight: bold;
  }

  .td__htp-content h3 {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }

  .td__htp-content h1 {
    font-size: 42px;
    line-height: 56px;
  }

  .td__htp-content p {
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
  }
  .company .card {
    
    margin-left:0px;
    margin-right:0px;
    
  }

}

@media screen and (max-width: 490px) {
  .company .card {
    
    margin-left:0px;
    margin-right:0px;
    
  }
  .td__header-content h2 {
    font-size: 34px;
    line-height: 48px;
  }

  .td__header-content h3 {
    font-size: 12px;
    line-height: 24px;
  }

  .td__header-content h1 {
    font-size: 36px;
    line-height: 50px;
  }

  .td__header-content p {
    font-size: 10px;
    line-height: 22px;
  }
  .td__header-content__input {
    width: 100%;
   
  }
  .td__htp-content h2 {
    font-size: 34px;
    line-height: 48px;
    font-weight: bold;
  }

  .td__htp-content h3 {
    font-size: 12px;
    line-height: 24px;
    font-weight: bold;
  }

  .td__htp-content h1 {
    font-size: 36px;
    line-height: 50px;
  }

  .td__htp-content p {
    font-size: 10px;
    line-height: 22px;
    font-weight: bold;
  }
}

.td__header-content__input input {
  flex: 2;
  width: 70%;
  min-height: 30px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 0 1rem;
  outline: none;
  color: rgb(65, 63, 63);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  
}

.td__header-content__input {
  width: 80%;

  display: flex;
  flex-direction: row;

}
.td__header-content__inputwp {
  width: 100%;

  display: flex;
  flex-direction: row;

}
.td__header-content__inputwp input {
  flex: 2;
  width: 100%;
  min-height: 30px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 0 1rem;
  outline: none;
  color: rgb(65, 63, 63);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  
}
.leftbg {
  top: 85px;
  left: 0px;
  width: 100%;
  height: 599px;

  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  opacity: 1;
}

.rightbg {
  background-color: #fff;
  height: 599px;
}

.txt-reg {
  top: 30px !important;
  left: inherit !important;
  width: inherit !important;
  height: inherit !important;
  font-size: 12px !important;
}

.input-reg {
  top: 200px !important;
  left: 0px !important;
}

.wrap {
  text-align: center;
}

.resp {
  display: inline-block;
  text-align: left;
  width: 21%;
  margin-right: 1%;
}

.resp.fixed {
  width: 100px;
  margin-right: 10px;
}



@media screen and (max-width: 550px) {
 
  .company .card {
    
    margin-left:0px;
    margin-right:0px;
    
  }
 
  .td__header-content__input {
    width: 100%;
   
  }
}

#obj1 {
  float: right;
  background-image: url('./image/MV.jpg');
  width: 100%;
  height: 200px;
  margin-left: 1.5rem;
  -webkit-animation: mymove 20s infinite;
  /* Chrome, Safari, Opera */
  animation: mymove 20s infinite;
  animation-delay: 2s;
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  /* IE 9 */
  margin-bottom: 70px;
}

/* Standard syntax */
@keyframes mymove {
  80% {
    transform: rotate(10deg);
  }
}